<template>
  <v-form ref="form">
    <v-row dense>
      <!-- CAMPO MATERIA ASEGURADA -->
      <v-col cols="12">
        <LabelComponent :text="select_product_type.label" />
        <v-autocomplete
          class="white"
          :items="branch?.materias ?? []"
          v-model="select_product_type.selected"
          item-text="descripcionMateria"
          return-object
          :placeholder="select_product_type.placeholder"
          :rules="select_product_type.rules"
          @change="SelectProduct"
          mandatory
          outlined
          append-icon="mdi-chevron-down"
          name="materia_aseg"
        />
      </v-col>

      <!-- NUEVO O USADO -->
      <v-col cols="12" class="pb-4 mb-4">
        <p class="pb-2">{{ select_new_vehicle.label }}</p>

        <!-- CAMPO VEHICULO NUEVA O USADO -->
        <v-btn-toggle v-model="select_new_vehicle.value" mandatory>
          <v-btn
            v-if="select_new_vehicle.brand_new"
            large
            :x-large="$vuetify.breakpoint.xl"
            text
            block
            color="primary"
            :value="true"
            class="text-none py-7"
            >Vehículo nuevo</v-btn
          >
          <v-btn
            v-if="select_new_vehicle.brand_used"
            :value="false"
            large
            :x-large="$vuetify.breakpoint.xl"
            block
            text
            color="primary"
            class="text-none py-7"
            >Vehículo usado</v-btn
          >
        </v-btn-toggle>
        <p class="caption mt-2">
          <v-icon color="info" left small>mdi-information</v-icon>
          {{ getHintVehicleUse }}
        </p>
      </v-col>

      <v-col cols="12">
        <!-- CAMPO MARCA -->
        <LabelComponent :text="select_brand.label" />
        <v-autocomplete
          outlined
          :items="getBrands"
          :disabled="!select_product_type.selected"
          v-model="select_brand.selected"
          return-object
          :placeholder="select_brand.placeholder"
          :rules="select_brand.rules"
          :loading="loadingBrand"
          @change="change"
          item-text="marca_descripcion"
          class="white"
          append-icon="mdi-chevron-down"
          name="marca"
        />
      </v-col>

      <v-col cols="12" md="6">
        <!-- CAMPO MODELO -->
        <LabelComponent :text="select_model.label" />
        <v-autocomplete
          outlined
          :disabled="!select_product_type.selected || !select_brand.selected"
          :items="getModels"
          v-model="select_model.selected"
          return-object
          :placeholder="select_model.placeholder"
          :rules="select_model.rules"
          :loading="loadingModels"
          item-text="modelo_descripcion"
          class="white"
          append-icon="mdi-chevron-down"
          name="modelo"
        />
      </v-col>

      <v-col cols="12" md="6">
        <!-- CAMPO ANIO -->
        <LabelComponent :text="select_year.label" />
        <v-select
          class="white mb-3"
          :items="years"
          v-model="select_year.selected"
          item-text="label"
          item-value="value"
          :placeholder="select_year.placeholder"
          :loading="yearsLoading"
          :rules="select_year.rules"
          mandatory
          outlined
          append-icon="mdi-chevron-down"
          name="anio"
        />
      </v-col>

      <v-col cols="12" :class="{ 'mb-6': !(documentType == 1) }">
        <p>¿El vehículo cuenta con placa o está en trámite?</p>
        <v-radio-group
          v-model="documentType"
          row
          mandatory
          hide-details
          @change="handlerChangePatente"
          :rules="[(v) => !!v || 'Debe seleccionar una opción']"
        >
          <v-row no-gutters>
            <v-col cols="12" md="6">
              <v-radio label="Placa" :value="1" />
              <!-- CAMPO PATENTE -->
              <!-- <v-fade-transition> -->
              <v-text-field
                v-if="documentType == 1"
                v-model="patente.value"
                outlined
                persistent-hint
                :rules="patente.rules"
                :placeholder="patente.placeholder"
                @keyup="patente.value = patente.value.toUpperCase()"
                name="patente"
                class="mt-2"
              />
              <!-- </v-fade-transition> -->
            </v-col>
            <v-col cols="12" md="6" v-if="select_new_vehicle.value">
              <v-radio label="En trámite" :value="2" />
            </v-col>
          </v-row>
        </v-radio-group>
      </v-col>

      <v-col cols="12" md="6">
        <!-- CAMPO DE USO DE PRODUCTO -->
        <LabelComponent :text="select_product_use.label" />
        <v-select
          class="white mb-3"
          :items="branch?.uso_producto ?? []"
          v-model="select_product_use.selected"
          item-text="uso_producto_descripcion"
          return-object
          item-disabled="eliminado"
          :placeholder="select_product_use.placeholder"
          :rules="select_product_use.rules"
          mandatory
          outlined
          append-icon="mdi-chevron-down"
          name="uso_producto"
        />
      </v-col>

      <v-col cols="12" md="6">
        <!-- CAMPO DEPARTAMENTO -->
        <LabelComponent :text="'Departamento'" />
        <v-text-field
          label=""
          disabled
          height="50"
          class="pt-0"
          :value="
            dataClient?.region_descripcion ||
            locations.regiones.find((e) => e.value == dataClient?.region)
              ?.label ||
            dataClient?.region ||
            ''
          "
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6" class="mb-6">
        <!-- CAMPO PROVINCIA -->
        <LabelComponent :text="'Provincia'" />
        <v-text-field
          label=""
          disabled
          height="50"
          class="pt-0"
          :value="
            dataClient?.provincia_descripcion ||
            locations.provincias.find((e) => e.value == dataClient?.provincia)
              ?.label ||
            dataClient?.provincia ||
            ''
          "
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6" class="mb-6">
        <!-- CAMPO DISTRITO -->
        <LabelComponent :text="'Distrito'" />
        <v-text-field
          label=""
          disabled
          height="50"
          class="pt-0"
          :value="
            dataClient?.comuna_descripcion ||
            locations.comunas.find((e) => e.value == dataClient?.comuna)
              ?.label ||
            dataClient?.comuna ||
            ''
          "
        ></v-text-field>
      </v-col>

      <!-- CAMPO SUMA ASEGURADA -->
      <v-col cols="12" md="6">
        <LabelComponent :text="valor_asegurado.label" />
        <v-text-field
          v-model="valor_asegurado.value"
          outlined
          :rules="valor_asegurado.rules"
          name="suma_asegurada"
          class="mt-2 align-center"
          v-mask="priceMask"
        >
          <template v-slot:prepend-inner> US$ </template>
        </v-text-field>
      </v-col>

      <!-- CAMPO GPS -->
      <v-col cols="12" md="6" class="pl-3">
        <p>¿El vehículo cuenta con GPS?</p>
        <v-radio-group
          v-model="gps.value"
          mandatory
          row
          :rules="[...gps.rules, (v) => validarSumaAsegurada(v)]"
        >
          <v-radio label="Si" value="SI" />

          <v-radio label="No" value="NO" />
        </v-radio-group>
      </v-col>
    </v-row>

    <!-- === CAMPOS VEHICULOS PESADOS === -->
    <template
      v-if="
        select_product_type.selected?.codigoMateria === codes.heavyCar ||
        select_product_type.selected?.codigoMateria === codes.heavyCarLeasing
      "
    >
      <!-- CAMPO CARGA PELIGROSA -->
      <v-col cols="12" md="6" order="2">
        <LabelComponent :text="transporta_carga_peligrosa.label" />
        <v-radio-group v-model="transporta_carga_peligrosa.value" row>
          <v-radio label="SI" :value="true"></v-radio>
          <v-radio label="NO" :value="false"></v-radio>
        </v-radio-group>
      </v-col>

      <!-- CAMPO TIPO CARGA PELIGROSA -->
      <v-col cols="12" md="6" order="2">
        <LabelComponent :text="carga_peligrosa.label" />
        <v-autocomplete
          :disabled="!transporta_carga_peligrosa.value"
          :items="carga_peligrosa.items"
          v-model="carga_peligrosa.value"
          return-object
          outlined
          :rules="carga_peligrosa.rules"
          :placeholder="carga_peligrosa.placeholder"
        ></v-autocomplete>
      </v-col>

      <!-- CAMPO TIPO ACOPLADO -->
      <v-col cols="12" md="6">
        <LabelComponent :text="tipo_acoplado.label" />
        <v-autocomplete
          :items="tipo_acoplado.items"
          v-model="tipo_acoplado.value"
          return-object
          outlined
          :rules="tipo_acoplado.rules"
          :placeholder="tipo_acoplado.placeholder"
        ></v-autocomplete>
      </v-col>

      <!-- CAMPO VALOR COMERCIAL ACOPLADO -->
      <v-col cols="12" md="6">
        <LabelComponent :text="valor_comercial_acoplado.label" />
        <v-text-field
          :placeholder="valor_comercial_acoplado.placeholder"
          v-model="valor_comercial_acoplado.value"
          v-mask="priceMask"
          outlined
          persistent-hint
          :rules="valor_comercial_acoplado.rules"
          ><template v-slot:prepend-inner> US$ </template></v-text-field
        >
      </v-col>
    </template>

    <!-- PLAZO DE CREDITO -->
    <v-col
      cols="12"
      v-if="
        select_product_type.selected?.codigoMateria === codes.carLeasing ||
        select_product_type.selected?.codigoMateria === codes.heavyCarLeasing
      "
    >
      <LabelComponent :text="select_plazo.label" />
      <v-text-field
        v-model="select_plazo.value"
        type="number"
        :placeholder="select_plazo.placeholder"
        :rules="select_plazo.rules"
        outlined
      ></v-text-field>
    </v-col>

    <!-- CAMPOS DE VENTA ASISTIDA -->
    <v-fade-transition>
      <div v-if="assistedSelling" class="my-6">
        <h4 class="mb-3">Venta de Terceros</h4>
        <v-fade-transition>
          <v-row v-if="dealers.length > 0">
            <!-- CAMPO DE CONSECIONARIOS -->
            <v-col cols="12" md="4">
              <v-autocomplete
                :items="dealers"
                v-model="select_dealer.selected"
                return-object
                :label="select_dealer.label"
                :placeholder="select_dealer.placeholder"
                outlined
                dense
                :rules="select_dealer.rules"
                item-text="canal_descripcion"
                @change="
                  $emit('set-dealer', $event),
                    (select_branchs.selected = null),
                    (select_sellers.selected = null)
                "
                class="white"
                append-icon="mdi-chevron-down"
                name="concesionario"
                :loading="loadingDealers"
              ></v-autocomplete>
            </v-col>
            <!-- CAMPO DE SUCURSALES -->
            <v-col cols="12" md="4">
              <v-autocomplete
                :items="branches"
                v-model="select_branchs.selected"
                return-object
                :label="select_branchs.label"
                :placeholder="select_branchs.placeholder"
                :rules="select_branchs.rules"
                @change="
                  $emit('set-branch', $event), (select_sellers.selected = null)
                "
                outlined
                dense
                item-text="canal_descripcion"
                class="white"
                append-icon="mdi-chevron-down"
                name="sucursal"
                :loading="loadingBranches"
              ></v-autocomplete>
            </v-col>
            <!-- CAMPO DE VENDEDORES -->
            <v-col cols="12" md="4">
              <v-autocomplete
                :items="sellers"
                v-model="select_sellers.selected"
                return-object
                :label="select_sellers.label"
                :placeholder="select_sellers.placeholder"
                :rules="select_sellers.rules"
                outlined
                dense
                :item-text="
                  (item) =>
                    `${item.nombre}  ${item.primer_apellido}  ${item.segundo_apellido}`
                "
                class="white"
                append-icon="mdi-chevron-down"
                name="vendedores"
                :loading="loadingSellers"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <div v-else>
            <v-alert border="left" colored-border type="warning" elevation="2">
              No se han encontrado concesionarios registrados
            </v-alert>
          </div>
        </v-fade-transition>
      </div>
    </v-fade-transition>

    <!-- MODAL GPS -->
    <v-dialog
      width="472px"
      v-model="openGpsModal"
      attach="#main-container"
      @click:outside="openGpsModal = false"
    >
      <v-card outlined>
        <v-card-title class="mt-1">
          <b> GPS del vehículo </b>
          <v-spacer></v-spacer>
          <v-icon class="global-pointer mr-3" @click="openGpsModal = false"
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-card-text>
          <v-alert type="warning" :value="true" border="left" colored-border>
            <span
              >Recuerda que para <b>asegurar este vehículo</b> debe contar con
              <b>GPS</b>
            </span>
          </v-alert>
          <div class="d-flex justify-center">
            <v-btn
              class="text-none px-9"
              rounded
              color="primary"
              @click="openGpsModal = false"
            >
              Entendido
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-form>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import LabelComponent from "@/components/Inputs/Label.vue";

import { validarNumerico, validarMonto } from "@/store/resources/validate.js";

import createNumberMask from "text-mask-addons/dist/createNumberMask";

const currencyMask = createNumberMask({
  prefix: "",

  includeThousandsSeparator: true,
  allowNegative: false,
  thousandsSeparatorSymbol: ".",
  integerLimit: 3000000000,
});

export default {
  name: "NewQuotCarPeruForm",
  components: {
    LabelComponent,
  },
  props: {
    branch: {
      type: Object,
      default: () => null,
    },
    dataClient: {
      type: Object,
      default: () => {},
    },
    dataCategory: {
      type: Object,
      default: () => {},
    },
    assistedSelling: {
      type: Boolean,
      default: false,
    },
    dealers: {
      type: Array,
      default: () => [],
    },
    loadingDealers: {
      type: Boolean,
      default: false,
    },
    branches: {
      type: Array,
      default: () => [],
    },
    loadingBranches: {
      type: Boolean,
      default: false,
    },
    sellers: {
      type: Array,
      default: () => [],
    },
    loadingSellers: {
      type: Boolean,
      default: false,
    },
    rut: {
      type: String,
      default: "",
    },
    codes: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    this.fillYears();
    this.fillCarUse();
  },
  data: () => ({
    yearsLoading: false,
    carUseLoading: false,
    loadingBrand: false,
    loadingModels: false,
    formType: 0,
    formData: null,
    priceMask: currencyMask,
    select_product_type: {
      label: "Tipo de vehículo*",
      placeholder: "Seleccione una materia asegurada",
      selected: null,
      rules: [(value) => !!value || "Debe seleccionar una materia asegurada."],
    },
    select_new_vehicle: {
      label: "¿El vehículo a cotizar es nuevo o usado?*",
      value: undefined,
      brand_new: false,
      brand_used: false,
      rules: [
        (value) =>
          !!value || "Porfavor especifique si el vehículo es nuevo o usado.",
      ],
    },
    select_year: {
      label: "Año*",
      selected: "",
      placeholder: "Año",
      rules: [(value) => !!value || "Seleccione un año."],
    },
    select_brand: {
      label: "Marca*",
      selected: null,
      placeholder: "Buscar Marca",
      rules: [(value) => !!value || "Debe seleccionar la marca."],
    },
    select_model: {
      label: "Modelo*",
      selected: null,
      placeholder: "Buscar Modelo",
      rules: [(value) => !!value || "Debe seleccionar el modelo."],
    },
    select_product_use: {
      label: "Uso del Vehículo*",
      selected: "",
      placeholder: "Seleccione un uso del producto",
      rules: [
        (value) => !!value || "Debe seleccionar un uso para el Producto.",
      ],
    },
    select_dealer: {
      label: "Concesionario*",
      selected: null,
      placeholder: "Seleccione un concesionario",
      rules: [(value) => !!value || "Debe seleccionar un concesionario."],
    },
    select_branchs: {
      label: "Sucursal*",
      selected: null,
      placeholder: "Seleccione una sucursal",
      rules: [(value) => !!value || "Debe seleccionar una sucursal."],
    },
    select_sellers: {
      label: "Vendedor*",
      selected: null,
      placeholder: "Seleccione un vendedor",
      rules: [(value) => !!value || "Debe seleccionar un vendedor."],
    },
    patente: {
      label: "",
      value: "",
      rules: [(value) => !!value || "Debe especificar una placa."],
    },
    valor_asegurado: {
      label: "Suma asegurada*",
      value: 0,
      placeholder: "Escriba el valor asegurado...",
      rules: [
        (value) => !!value || "El campo no puede quedar vacío",
        (value) => validarMonto(value),
      ],
    },
    // == Leasing Vehicles Fields ==
    select_plazo: {
      label: "Plazo de crédito (meses)*",
      value: undefined,
      placeholder: "Escriba un plazo",
      rules: [
        (value) => !!value || "Debe escribir un plazo de crédito.",
        (value) => validarNumerico(value),
      ],
    },
    gps: {
      label: "¿El vehículo cuenta con GPS?*",
      value: 0,
      rules: [(value) => !!value || "Debe seleccionar una opción"],
    },
    documentType: 1,
    openGpsModal: false,
    // == Heavy Vehicles Fields ==
    transporta_carga_peligrosa: {
      label: "¿Transporta carga peligrosa?",
      value: false,
      rules: [(value) => !!value || "Debe seleccionar una opción."],
    },
    carga_peligrosa: {
      label: "Tipo de carga peligrosa*",
      value: null,
      placeholder: "Seleccione un tipo de carga",
      rules: [(value) => !!value || "Debe seleccionar un tipo de carga."],
      items: [],
    },
    tipo_acoplado: {
      label: "Tipo de acoplado*",
      value: null,
      placeholder: "Seleccione un tipo de acoplado",
      rules: [(value) => !!value || "Debe seleccionar un tipo de acoplado."],
      items: [],
    },
    valor_comercial_acoplado: {
      label: "Suma asegurada acoplado*",
      value: "",
      placeholder: "Especifique una suma del acoplado...",
      rules: [
        (value) => !!value || "El campo no puede quedar vacío",
        (value) => validarMonto(value),
      ],
    },
  }),
  computed: {
    ...mapGetters("Users", ["getBrands", "getModels"]),
    years() {
      return this.$store.getters.yearsList;
    },
    getHintVehicleUse() {
      let hintText =
        "Haga click para establecer el estado del vehiculo como nuevo o usado.";
      if (
        this.select_new_vehicle.brand_new &&
        !this.select_new_vehicle.brand_used
      ) {
        hintText = "El canal solo permite vehiculos nuevos";
      }
      if (
        this.select_new_vehicle.brand_used &&
        !this.select_new_vehicle.brand_new
      ) {
        hintText = "El canal solo permite vehiculos usados";
      }
      return hintText;
    },
    locations() {
      const { regiones, provincias, comunas } = this.$store.getters.getLocation;
      return { regiones, provincias, comunas };
    },
  },
  methods: {
    ...mapActions("Users", ["findModels", "findBrandsType"]),
    SelectProduct() {
      this.formType = 1;
      this.loadingBrand = true;
      this.findBrandsType(this.formType).finally(
        () => (this.loadingBrand = false)
      );
    },
    change() {
      this.loadingModels = true;
      this.findModels({
        tipo_vehiculo: this.formType,
        marca: this.select_brand.selected.marca,
      }).finally(() => (this.loadingModels = false));
    },

    fillCarUse() {
      this.carUseLoading = true;
      this.$store
        .dispatch("REQUEST_CHANNEL_VEHICLE_USE")
        .then((response) => {
          this.select_new_vehicle.brand_new = response.habilita_vehiculo_nuevo;
          this.select_new_vehicle.disabled_false = response.brand_used;
        })
        .finally(() => {
          this.carUseLoading = false;
        });
    },

    fillYears() {
      this.yearsLoading = true;
      this.$store
        .dispatch("REQUEST_QUOTATION_YEARS")
        .finally(() => (this.yearsLoading = false));
    },

    handlerChangePatente(e) {
      this.patente.value = e == 2 ? "FACTURA" : "";
    },

    validarSumaAsegurada(value) {
      var valor_asegurado = parseInt(
        this.valor_asegurado.value.replace(".", "")
      );
      if (value !== "SI" && valor_asegurado >= 50000) {
        this.openGpsModal = true;
        return "Debe contar con GPS";
      }
      return true;
    },

    submit() {
      return new Promise((resolve) => {
        // Establecer la data para enviar
        const dataNewQuot = {
          producto: this.select_product_type.selected?.idMateria,
          producto_descripcion:
            this.select_product_type.selected?.descripcionMateria,
          nuevo: this.select_new_vehicle.value,
          marca: this.select_brand.selected.marca,
          patente: this.patente.value,
          modelo: this.select_model.selected.modelo,
          anio: this.select_year.selected,
          uso_producto: this.select_product_use.selected.uso_producto,
          uso_producto_descripcion:
            this.select_product_use.selected.uso_producto_descripcion,
          uso_producto_codigo: this.select_product_use.selected.codigo,
          obj_marca: this.select_brand.selected,
          obj_modelo: this.select_model.selected,
          gps: this.gps.value,
          suma_asegurada: this.valor_asegurado.value.replace(/\./g, ""),
          ...this.dataClient,
          ...this.dataCategory,
        };
        // == Campos para tipo Leasing ==
        const codigoProducto = this.select_product_type.selected?.codigoMateria;
        if (
          codigoProducto === this.codes.carLeasing ||
          codigoProducto === this.codes.heavyCarLeasing
        ) {
          dataNewQuot.plazos = this.select_plazo.value;
        }
        // == Campos para tipo vehiculo pesado ==
        if (
          codigoProducto === this.codes.heavyCar ||
          codigoProducto === this.codes.heavyCarLeasing
        ) {
          dataNewQuot.valor_comercial_acoplado =
            this.valor_comercial_acoplado.value.replace(/\./g, "");
          dataNewQuot.tipo_acoplado = this.tipo_acoplado.value;
          dataNewQuot.transporta_carga_peligrosa =
            this.transporta_carga_peligrosa.value;
          dataNewQuot.carga_peligrosa = this.carga_peligrosa.value;
        }
        // == Campos para venta asistida ==
        if (this.assistedSelling) {
          dataNewQuot.venta_terceros = {
            venta_asistida: this.assistedSelling,
            concesionario: this.select_dealer.selected?._id ?? "",
            concesionario_descripcion:
              this.select_dealer.selected?.canal_descripcion ?? "",
            sucursal: this.select_branchs.selected?._id ?? "",
            sucursal_descripcion:
              this.select_branchs.selected?.canal_descripcion ?? "",
            vendedor: this.select_sellers.selected?._id ?? "",
            vendedor_descripcion:
              this.select_sellers.selected?.vendedor_descripcion ?? "",
          };
        } else {
          dataNewQuot.venta_terceros = {
            venta_asistida: false,
          };
        }
        // == Data de Verificacion ==
        const payload = {
          rut: this.dataClient.numero_documento || this.rut,
          patente: this.patente.value,
          data: dataNewQuot,
        };

        this.formData = payload;
        resolve(payload);
      });
    },
  },
};
</script>

<style scoped>
.inputInner {
  height: 45px;
  display: flex;
  background-color: #f4f3f6;
  border-radius: 4px 0px 0px 4px;
  align-items: center;
  padding: 0 8px;
}

.gps.v-input {
  margin-top: 0 !important;
  padding-top: 8px;
}
</style>
