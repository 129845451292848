<template>
  <div>
    <div class="pl-7 pr-10 py-10">
      <v-card flat class="invisible d-flex flex-column">
        <h4 class="mb-3">Pendientes del Cliente</h4>
        <template v-if="user?.cliente">
          <p class="font-weight-bold text-capitalize subtitle-1">
            <span v-if="user.tipo_persona == 'JUR'">
              {{ user.cliente?.razon_social ?? "" }}
            </span>
            <span v-else>
              {{
                `${user.cliente?.nombre ?? ""} ${
                  user.cliente?.primer_apellido ?? ""
                } ${user.cliente?.segundo_apellido ?? ""}`
              }}
            </span>
          </p>
          <div class="d-flex">
            <p class="subtitle-2">
              <span class="font-weight-bold">{{
                user.cliente?.tipo_documento
              }}</span>
              <span class="ml-2 font-weight-regular">
                <span v-if="isChile">{{
                  user.cliente?.numero_documento ?? "" | rutFormat
                }}</span>
                <span v-else>
                  {{ user.cliente?.numero_documento ?? "" }}
                </span>
              </span>
            </p>
          </div>
          <div class="d-flex" v-if="isChile">
            <p class="font-weight-bold subtitle-2">
              Comuna:
              <span class="ml-2 font-weight-regular">{{
                user?.comuna_descripcion ?? ""
              }}</span>
            </p>
          </div>
        </template>

        <v-divider class="mb-6 mt-4"></v-divider>
        <div v-if="!user?.cliente">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
        <QuotationPending
          v-if="showQuotes"
          :loading="loadingQuotes"
          :quotations="quotations"
          :docNumber="user?.cliente?.numero_documento ?? ''"
          :docType="user?.cliente?.tipo_documento ?? ''"
        />
        <DocumentPending
          v-if="showDocs"
          :class="quotations.length > 0 ? 'mt-5' : 'mt-1'"
          :loading="loadingDocs"
          :documents="documents"
          :rut="user?.cliente?.numero_documento ?? ''"
        />
      </v-card>
    </div>
  </div>
</template>

<script>
import QuotationPending from "@/components/User-Components/Pending/PendingQuot";
import DocumentPending from "@/components/User-Components/Pending/PendingDocs";

import CountryMixin from "@/Mixins/CountryMixin.js";

export default {
  name: "ClientPendingBar",
  mixins: [CountryMixin],
  props: {
    documents: {
      type: Array,
      default: () => [],
      description: "Documentos pendientes",
    },
    quotations: {
      type: Array,
      default: () => [],
      description: "Simulaciones pendientes",
    },
    showDocs: {
      type: Boolean,
      default: true,
      description: "Mostrar documentos pendientes",
    },
    showQuotes: {
      type: Boolean,
      default: true,
      description: "Mostrar Simulaciones pendientes",
    },
    loadingDocs: {
      type: Boolean,
      default: false,
    },
    loadingQuotes: {
      type: Boolean,
      default: false,
    },
    user: {
      type: [Object, Array],
      default: null,
      description: "Datos del cliente",
    },
  },
  components: {
    QuotationPending,
    DocumentPending,
  },
};
</script>
